<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="row justify-content-end p-4">
                        <button class="btn btn-danger" @click="deleteItem">{{ $t('delete') }}</button>
                        <custom-export-data v-if="$can('accounts_with_cost_center.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.accounts_with_cost_center')"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                    <template slot="h__selected">
                        <input type="checkbox" id="h__checkbox" @click="checkAll($event)" >
                    </template>
                    <template slot="selected" slot-scope="props">
                        <input type="checkbox" id="checkbox" v-model="ids" :value="props.row.id" :checked="isChecked(props.row.id)">
                    </template>
                </v-server-table>
                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-accounts-with-cost-center",
        components: {},
        data() {
            return {
                mainRoute: 'accounting/account-assign-cost-center/list',
                subMainRoute: 'accounting/account-assign-cost-center/delete/list',
                showAdvancedSearch: false,
                dataList: [],
                filters: {},
                accounts_list: [],
                columns: ['selected', 'account_id', 'account', 'account_code', 'cost_center', 'center_code', 'percentage'],
                ids:[],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('accounts_with_cost_center.journal_account_id')] = 'account_id';
                fields[this.$t('accounts_with_cost_center.journal_account')] = 'account';
                fields[this.$t('accounts_with_cost_center.journal_account_code')] = 'account_code';
                fields[this.$t('accounts_with_cost_center.cost_center')] = 'cost_center';
                fields[this.$t('accounts_with_cost_center.center_code')] = 'center_code';
                fields[this.$t('accounts_with_cost_center.percentage')] = 'percentage';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        selected: '',
                        account_id: that.$t('accounts_with_cost_center.journal_account_id'),
                        account: that.$t('accounts_with_cost_center.journal_account'),
                        account_code: that.$t('accounts_with_cost_center.journal_account_code'),
                        cost_center: that.$t('accounts_with_cost_center.cost_center'),
                        center_code: that.$t('accounts_with_cost_center.center_code'),
                        percentage: that.$t('accounts_with_cost_center.percentage')+' (%)',

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.accounts_with_cost_center")}]);
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },

            actionDelete() {
                ApiService.delete(this.subMainRoute, {params:{ids: this.ids}}).then((response) => {
                    this.getFetch();
                    this.ids = [];
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            deleteItem() {
                this.$confirmAlert('', this.actionDelete, []);
            },
            checkAll(event) {
                if (event.target.checked){
                    this.dataList.map(row => {
                        if (!this.ids.includes(row.id)){
                            this.ids.push(row.id);
                        }
                    });
                }else {
                    this.ids = [];
                }
            },
            isChecked(id){
                return this.ids.includes(id);
            }
        },

    };
</script>
